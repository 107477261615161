import {
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_OWN_PROFILE_SUCCESS,
} from '@constants/iTrackerConstants';

export const onFetchOwnProfile = () => ({
  type: FETCH_OWN_PROFILE_REQUEST,
});

export const onFetchOwnProfileSuccess = (payload) => ({
  type: FETCH_OWN_PROFILE_SUCCESS,
  payload,
});
