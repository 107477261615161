export const GET_ALL_ISSUES = 'GET_ALL_ISSUES';
export const GET_ALL_ISSUES_SUCCESS = 'GET_ALL_ISSUES_SUCCESS';
export const GET_VERSIONS = 'GET_VERSIONS';
export const GET_VERSIONS_SUCCESS = 'GET_VERSIONS_SUCCESS';
export const GET_STATUSES = 'GET_STATUSES';
export const GET_STATUSES_SUCCESS = 'GET_STATUSES_SUCCESS';
export const GET_ISSUES = 'GET_ISSUES';
export const GET_ISSUES_SUCCESS = 'GET_ISSUES_SUCCESS';
export const GET_CUSTOM_FIELD_OPTIONS = 'GET_CUSTOM_FIELD_OPTIONS';
export const GET_CUSTOM_FIELD_OPTIONS_SUCCESS = 'GET_CUSTOM_FIELD_OPTIONS_SUCCESS';
export const GET_PRIORITIES = 'GET_PRIORITIES';
export const GET_PRIORITIES_SUCCESS = 'GET_PRIORITIES_SUCCESS';
export const GET_ASSIGNEES = 'GET_ASSIGNEES';
export const GET_ASSIGNEES_SUCCESS = 'GET_ASSIGNEES_SUCCESS';
export const GET_REPORTERS = 'GET_REPORTERS';
export const GET_REPORTERS_SUCCESS = 'GET_REPORTERS_SUCCESS';
export const GET_LABELS = 'GET_LABELS';
export const GET_LABELS_SUCCESS = 'GET_LABELS_SUCCESS';

export const FILTER_ISSUES = 'FILTER_ISSUES';
export const FILTER_ISSUES_SUCCESS = 'FILTER_ISSUES_SUCCESS';
export const ADD_FILTER = 'ADD_FILTER';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';

export const TOGGLE_TABLE_COLUMNS = 'TOGGLE_TABLE_COLUMNS';
export const GET_TABLE_COLUMNS = 'GET_TABLE_COLUMNS';

export const FETCH_OWN_PROFILE_REQUEST = 'FETCH_OWN_PROFILE_REQUEST';
export const FETCH_OWN_PROFILE_SUCCESS = 'FETCH_OWN_PROFILE_SUCCESS';

export const PROJECT_ID = '10100';
