import { all } from 'redux-saga/effects';
import notesSagas from '@sagas/Notes';
import FilterSaga from '@sagas/iTracker/FilterSaga';
import UserSaga from '@sagas/iTracker/UserSaga';

export default function* rootSaga() {
  yield all([
    notesSagas(),

    FilterSaga(),
    UserSaga(),
  ]);
}
