import React, { Component } from 'react';
import {
  Menu, Select, Input, DatePicker,
} from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import CustomScrollbars from 'util/CustomScrollbars';
import {
  fetchStatuses,
  fetchVersions,
  filterIssues,
  fetchCustomFieldOptions,
  fetchPriorities,
  fetchAssignees,
  fetchReporters,
  fetchLabels,
  addFilter,
} from '@actions/iTracker/FilterActions';
import { onFetchOwnProfile } from '@actions/iTracker/UserActions';
import IntlMessages from '@util/IntlMessages';
import SidebarLogo from './SidebarLogo';

const { SubMenu } = Menu;
const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

/**
 * @description Handles sidebar menu
 * @returns {JSX}
 */
class SidebarContent extends Component {
  /**
   * @description Renders priorities list
   * @param {Array} prioritiesList
   * @returns {JSX}
   */
  static renderPriorities(prioritiesList) {
    const JSXToReturn = prioritiesList && prioritiesList.map((priority) => (
      <Option
        key={priority.id}
        value={priority.name}
      />
    ));

    return JSXToReturn;
  }

  /**
   * @description Renders assignees list
   * @param {Array} usersList
   * @returns {JSX}
   */
  static renderUsers(usersList) {
    const JSXToReturn = usersList && usersList.map((user) => (
      <Option
        key={user.accountId}
        value={user.displayName}
      />
    ));

    return JSXToReturn;
  }

  /**
   * @description Renders versions list
   * @param {Array} versionsList
   * @returns {JSX}
   */
  static renderVersions(versionsList) {
    const JSXToReturn = versionsList && versionsList.map((version) => (
      <Option
        key={version.id}
        value={version.name}
      />
    ));

    return JSXToReturn;
  }

  /**
   * @description Renders ticket status list
   * @param {Array} statusesList
   * @returns {JSX}
   */
  static renderTicketStatus(statusesList) {
    const JSXToReturn = statusesList && statusesList.map((status) => (
      <Option
        key={status.id}
        value={status.name}
      />
    ));

    return JSXToReturn;
  }

  static renderLabels(labels) {
    const JSXToReturn = labels && labels.map((label) => (
      <Option
        key={label}
        value={label}
      />
    ));

    return JSXToReturn;
  }

  constructor(props) {
    super(props);

    this.state = {
      openKeys: [],
      productTypeSelect: false,
      orgNameSelect: false,
      caseTicketSelect: false,
      reporterSelect: false,
      assigneeSelect: false,
      roadmapStatusSelect: false,
      CABStatusSelect: false,
      ticketStatusSelect: false,
      customerComplaintSelect: false,
      fixVersionSelect: false,
      labelSelect: false,
      productOwnerSelect: false,
      prioritySelect: false,
      requestTypeSelect: false,
      dateCreatedSelect: false,
      dateUpdateSelect: false,
      dateResolvedSelect: false,
      caseTicketSearching: false,
      labelSearching: false,
      requestTypeSearching: false,
    };

    this.rootSubmenuKeys = [
      'productType',
      'organizationName',
      'caseTicket',
      'reporter',
      'assignee',
      'roadmapStatus',
      'CABStatuses',
      'ticketStatus',
      'customerComplaint',
      'fixVersions',
      'labels',
      'productOwner',
      'priority',
      'requestType',
      'dateCreated',
      'dateLastModified',
      'dateResolved',
    ];

    this.handleReporterChange = this.handleReporterChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleAssigneeChange = this.handleAssigneeChange.bind(this);
    this.handleFixVersionChange = this.handleFixVersionChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleProductTypeChange = this.handleProductTypeChange.bind(this);
    this.handleOrgNameChange = this.handleOrgNameChange.bind(this);
    this.handleCABStatusChange = this.handleCABStatusChange.bind(this);
    this.handleRoadmapStatusChange = this.handleRoadmapStatusChange.bind(this);
    this.handleProductOwnerChange = this.handleProductOwnerChange.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleCustomerComplaintChange = this.handleCustomerComplaintChange.bind(this);
    this.handleDateCreated = this.handleDateCreated.bind(this);
    this.handleUpdatedDate = this.handleUpdatedDate.bind(this);
    this.handleResolutionDate = this.handleResolutionDate.bind(this);

    this.delayedSearchQueryCaseTicket = _.debounce(this.handleCaseTicketSearch, 1000);
    this.delayedSearchRequestType = _.debounce(this.handleRequestTypeSearch, 1000);

    this.onFilterOpen = this.onFilterOpen.bind(this);

    this.loadFilterOptions = this.loadFilterOptions.bind(this);
  }

  componentDidMount() {
    const { fetchOwnProfile } = this.props;

    fetchOwnProfile();
  }

  /**
   * @description Check for only one filter to be opened
   * @param {Array} keys
   * @returns {void}
   */
  onFilterOpen(keys) {
    const { openKeys } = this.state;
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: keys });
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
    }
  }

  /**
   * @description Triggers filter by selected values for given field
   * @param {String} value
   * @param {String} field
   * @returns {void}
   */
  handleFilterChange(value, field) {
    const {
      onFilterIssues,
      onAddFilter,
      reporterFilter,
      assigneeFilter,
      statusFilter,
      searchWordFilter,
      fixVersionFilter,
      productTypeFilter,
      orgNameFilter,
      CABStatusFilter,
      roadmapStatusFilter,
      productOwnerFilter,
      priorityFilter,
      customerComplaintFilter,
      labelFilter,
      caseTicketFilter,
      requestTypeFilter,
      dateCreatedFilter,
      updatedDateFilter,
      resolutionFilter,
      page,
      pageSize,
    } = this.props;

    const filters = {
      status: statusFilter,
      reporter: reporterFilter,
      assignee: assigneeFilter,
      searchWord: searchWordFilter,
      fixVersion: fixVersionFilter,
      productType: productTypeFilter,
      orgName: orgNameFilter,
      CABStatus: CABStatusFilter,
      roadmapStatus: roadmapStatusFilter,
      productOwner: productOwnerFilter,
      priority: priorityFilter,
      customerComplaint: customerComplaintFilter,
      label: labelFilter,
      caseTicket: caseTicketFilter,
      requestType: requestTypeFilter,
      dateCreated: dateCreatedFilter,
      updatedDate: updatedDateFilter,
      resolutionDate: resolutionFilter,
    };

    const newFilters = {
      ...filters,
      [field]: value,
    };
    const startAt = page * pageSize - pageSize;

    onAddFilter(value, field);
    onFilterIssues(newFilters, startAt, pageSize);

    this.setState({
      caseTicketSearching: false,
      labelSearching: false,
      requestTypeSearching: false,
    });
  }

  /**
   * @description Set selected value for status in state
   * @param {String} value
   * @returns {void}
   */
  handleStatusChange(value) {
    this.handleFilterChange(value, 'status');
    if (value.length) {
      this.setState({ ticketStatusSelect: true });
    } else {
      this.setState({ ticketStatusSelect: false });
    }
  }

  /**
   * @description Set selected value for reporter in state
   * @param {String} value
   * @returns {void}
   */
  handleReporterChange(value) {
    this.handleFilterChange(value, 'reporter');
    if (value.length) {
      this.setState({ reporterSelect: true });
    } else {
      this.setState({ reporterSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for assignee
   * @param {String} value
   * @returns {void}
   */
  handleAssigneeChange(value) {
    this.handleFilterChange(value, 'assignee');
    if (value.length) {
      this.setState({ assigneeSelect: true });
    } else {
      this.setState({ assigneeSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for fix version
   * @param {String} value
   * @returns {void}
   */
  handleFixVersionChange(value) {
    this.handleFilterChange(value, 'fixVersion');
    if (value.length) {
      this.setState({ fixVersionSelect: true });
    } else {
      this.setState({ fixVersionSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for product type
   * @param {String} value
   * @returns {void}
   */
  handleProductTypeChange(value) {
    this.handleFilterChange(value, 'productType');
    if (value.length) {
      this.setState({ productTypeSelect: true });
    } else {
      this.setState({ productTypeSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for organization name
   * @param {String} value
   * @returns {void}
   */
  handleOrgNameChange(value) {
    this.handleFilterChange(value, 'orgName');
    if (value.length) {
      this.setState({ orgNameSelect: true });
    } else {
      this.setState({ orgNameSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for CAB status
   * @param {String} value
   * @returns {void}
   */
  handleCABStatusChange(value) {
    this.handleFilterChange(value, 'CABStatus');
    if (value.length) {
      this.setState({ CABStatusSelect: true });
    } else {
      this.setState({ CABStatusSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for roadmap status
   * @param {String} value
   * @returns {void}
   */
  handleRoadmapStatusChange(value) {
    this.handleFilterChange(value, 'roadmapStatus');
    if (value.length) {
      this.setState({ roadmapStatusSelect: true });
    } else {
      this.setState({ roadmapStatusSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for product owner
   * @param {String} value
   * @returns {void}
   */
  handleProductOwnerChange(value) {
    this.handleFilterChange(value, 'productOwner');
    if (value.length) {
      this.setState({ productOwnerSelect: true });
    } else {
      this.setState({ productOwnerSelect: false });
    }
  }

  handleLabelChange(value) {
    this.handleFilterChange(value, 'label');
    if (value.length) {
      this.setState({ labelSelect: true });
    } else {
      this.setState({ labelSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for priority
   * @param {String} value
   * @returns {void}
   */
  handlePriorityChange(value) {
    this.handleFilterChange(value, 'priority');
    if (value.length) {
      this.setState({ prioritySelect: true });
    } else {
      this.setState({ prioritySelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected values for customer complaint
   * @param {String} value
   * @returns {void}
   */
  handleCustomerComplaintChange(value) {
    this.handleFilterChange(value, 'customerComplaint');
    if (value.length) {
      this.setState({ customerComplaintSelect: true });
    } else {
      this.setState({ customerComplaintSelect: false });
    }
  }

  /**
   * @description Triggers filter function with searched value for labels
   * @param {String} value
   * @returns {void}
   */
  handleLabelSearch(value) {
    this.handleFilterChange(value, 'label');
    if (value.length) {
      this.setState({ labelSelect: true });
    } else {
      this.setState({ labelSelect: false });
    }
  }

  /**
   * @description Triggers filter function with searched value for case ticket
   * @param {String} value
   * @returns {void}
   */
  handleCaseTicketSearch(value) {
    this.handleFilterChange(value, 'caseTicket');
    if (value.length) {
      this.setState({ caseTicketSelect: true });
    } else {
      this.setState({ caseTicketSelect: false });
    }
  }

  handleRequestTypeSearch(value) {
    this.handleFilterChange(value, 'requestType');
    if (value.length) {
      this.setState({ requestTypeSelect: true });
    } else {
      this.setState({ requestTypeSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected dates from range picker for creation date
   * @param {String} value
   * @returns {void}
   */
  handleDateCreated(dates, dateStrings) {
    this.handleFilterChange(dateStrings, 'dateCreated');
    if (dateStrings[0].length && dateStrings[1].length) {
      this.setState({ dateCreatedSelect: true });
    } else {
      this.setState({ dateCreatedSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected dates
   * from range picker for last modification date
   * @param {String} value
   * @returns {void}
   */
  handleUpdatedDate(dates, dateStrings) {
    this.handleFilterChange(dateStrings, 'updatedDate');
    if (dateStrings[0].length && dateStrings[1].length) {
      this.setState({ dateUpdateSelect: true });
    } else {
      this.setState({ dateUpdateSelect: false });
    }
  }

  /**
   * @description Triggers filter function with selected dates from range picker for resolution date
   * @param {String} value
   * @returns {void}
   */
  handleResolutionDate(dates, dateStrings) {
    this.handleFilterChange(dateStrings, 'resolutionDate');
    if (dateStrings[0].length && dateStrings[1].length) {
      this.setState({ dateResolvedSelect: true });
    } else {
      this.setState({ dateResolvedSelect: false });
    }
  }

  /**
   * @description Triggers debouncer for searching by case ticket
   * @param {String} value
   * @returns {void}
   */
  searchCaseTicket(value) {
    this.delayedSearchQueryCaseTicket(value);
    this.setState({ caseTicketSearching: true });
  }

  searchRequestType(value) {
    this.delayedSearchRequestType(value);
    this.setState({ requestTypeSearching: true });
  }

  /**
   * @description.Loads all options by first click on filter item
   * @param {String} val
   * @returns {void}
   */
  loadFilterOptions(val) {
    const {
      productTypes,
      orgNames,
      assignable,
      reporters,
      roadmapStatus,
      CABStatus,
      statuses,
      customerComplaints,
      versions,
      labels,
      productOwner,
      priorities,
      onFetchStatuses,
      onFetchVersions,
      onFetchPriorities,
      onFetchAssignees,
      onFetchReporters,
      onFetchCustomFieldOptions,
      onFetchLabels,
    } = this.props;

    switch (val.key) {
      case 'productType':
        if (!productTypes) {
          onFetchCustomFieldOptions('customfield_10135');
        }
        break;

      case 'organizationName':
        if (!orgNames) {
          onFetchCustomFieldOptions('customfield_10115');
        }
        break;

      case 'roadmapStatus':
        if (!roadmapStatus) {
          onFetchCustomFieldOptions('customfield_10136');
        }
        break;

      case 'reporter':
        if (!reporters.length) {
          onFetchReporters();
        }
        break;

      case 'assignee':
        if (!assignable.length) {
          onFetchAssignees();
        }
        break;

      case 'CABStatuses':
        if (!CABStatus) {
          onFetchCustomFieldOptions('customfield_10140');
        }
        break;

      case 'ticketStatus':
        if (!statuses.length) {
          onFetchStatuses();
        }
        break;

      case 'customerComplaint':
        if (!customerComplaints) {
          onFetchCustomFieldOptions('customfield_10139');
        }
        break;

      case 'fixVersions':
        if (!versions.length) {
          onFetchVersions();
        }
        break;

      case 'labels':
        if (!labels.length) {
          onFetchLabels();
        }
        break;

      case 'productOwner':
        if (!productOwner) {
          onFetchCustomFieldOptions('customfield_10131');
        }
        break;

      case 'priority':
        if (!priorities.length) {
          onFetchPriorities();
        }
        break;

      default:
        break;
    }
  }

  render() {
    const {
      productTypes,
      orgNames,
      assignable,
      reporters,
      roadmapStatus,
      CABStatus,
      statuses,
      customerComplaints,
      versions,
      productOwner,
      priorities,
      labels,
      superAdmin,
    } = this.props;

    const {
      openKeys,
      productTypeSelect,
      orgNameSelect,
      caseTicketSelect,
      reporterSelect,
      assigneeSelect,
      roadmapStatusSelect,
      CABStatusSelect,
      ticketStatusSelect,
      customerComplaintSelect,
      fixVersionSelect,
      labelSelect,
      productOwnerSelect,
      prioritySelect,
      requestTypeSelect,
      dateCreatedSelect,
      dateUpdateSelect,
      dateResolvedSelect,
      caseTicketSearching,
      requestTypeSearching,
    } = this.state;

    return (
      <>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className="gx-sidebar-notifications">
            Filters
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              mode="inline"
              openKeys={openKeys}
              onOpenChange={this.onFilterOpen}
            >
              <SubMenu
                key="productType"
                className={productTypeSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.productType" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
                onTitleClick={(val) => this.loadFilterOptions(val)}
              >
                <Menu.Item>
                  <Select
                    options={productTypes}
                    showSearch
                    mode="multiple"
                    onChange={this.handleProductTypeChange}
                    allowClear
                  />
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="organizationName"
                className={orgNameSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.organizationName" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
                onTitleClick={(val) => this.loadFilterOptions(val)}
              >
                <Menu.Item>
                  <Select
                    options={orgNames ? orgNames.filter((org) => !org.disabled) : null}
                    showSearch
                    mode="multiple"
                    onChange={this.handleOrgNameChange}
                    allowClear
                  />
                </Menu.Item>
              </SubMenu>
              {
                superAdmin
                  ? (
                    <SubMenu
                      key="caseTicket"
                      className={caseTicketSelect ? 'select-with-values' : ''}
                      title={(
                        <span className="filter-heading">
                          <IntlMessages id="sidebar.filter.caseTicket" />
                          <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                          <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                        </span>
                      )}
                    >
                      <Menu.Item>
                        <Search
                          placeholder="Search a case ticket"
                          allowClear
                          size="middle"
                          loading={caseTicketSearching}
                          onChange={(e) => this.searchCaseTicket(e.target.value)}
                        />
                      </Menu.Item>
                    </SubMenu>
                  ) : null
              }
              <SubMenu
                key="reporter"
                className={reporterSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.reporter" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
                onTitleClick={(val) => this.loadFilterOptions(val)}
              >
                <Menu.Item>
                  <Select
                    showSearch
                    mode="multiple"
                    optionLabelProp="value"
                    onChange={this.handleReporterChange}
                    allowClear
                  >
                    {SidebarContent.renderUsers(reporters)}
                  </Select>
                </Menu.Item>
              </SubMenu>
              {
                superAdmin
                  ? (
                    <SubMenu
                      key="assignee"
                      className={assigneeSelect ? 'select-with-values' : ''}
                      title={(
                        <span className="filter-heading">
                          <IntlMessages id="sidebar.filter.assignee" />
                          <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                          <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                        </span>
                      )}
                      onTitleClick={(val) => this.loadFilterOptions(val)}
                    >
                      <Menu.Item>
                        <Select
                          showSearch
                          mode="multiple"
                          optionLabelProp="value"
                          onChange={this.handleAssigneeChange}
                          allowClear
                        >
                          {SidebarContent.renderUsers(assignable)}
                        </Select>
                      </Menu.Item>
                    </SubMenu>
                  ) : null
              }
              <SubMenu
                key="CABStatuses"
                className={CABStatusSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.CABStatus" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
                onTitleClick={(val) => this.loadFilterOptions(val)}
              >
                <Menu.Item>
                  <Select
                    options={CABStatus}
                    showSearch
                    mode="multiple"
                    onChange={this.handleCABStatusChange}
                    allowClear
                  />
                </Menu.Item>
              </SubMenu>
              {
                superAdmin
                  ? (
                    <SubMenu
                      key="roadmapStatus"
                      className={roadmapStatusSelect ? 'select-with-values' : ''}
                      title={(
                        <span className="filter-heading">
                          <IntlMessages id="sidebar.filter.roadmapStatus" />
                          <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                          <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                        </span>
                      )}
                      onTitleClick={(val) => this.loadFilterOptions(val)}
                    >
                      <Menu.Item>
                        <Select
                          options={roadmapStatus}
                          showSearch
                          mode="multiple"
                          onChange={this.handleRoadmapStatusChange}
                          allowClear
                        />
                      </Menu.Item>
                    </SubMenu>
                  ) : null
              }
              {
                superAdmin
                  ? (
                    <SubMenu
                      key="ticketStatus"
                      className={ticketStatusSelect ? 'select-with-values' : ''}
                      title={(
                        <span className="filter-heading">
                          <IntlMessages id="sidebar.filter.ticketStatus" />
                          <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                          <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                        </span>
                      )}
                      onTitleClick={(val) => this.loadFilterOptions(val)}
                    >
                      <Menu.Item>
                        <Select
                          showSearch
                          mode="multiple"
                          optionLabelProp="value"
                          onChange={this.handleStatusChange}
                          allowClear
                        >
                          {SidebarContent.renderTicketStatus(statuses)}
                        </Select>
                      </Menu.Item>
                    </SubMenu>
                  ) : null
              }
              {
                superAdmin
                  ? (
                    <SubMenu
                      key="customerComplaint"
                      className={customerComplaintSelect ? 'select-with-values' : ''}
                      title={(
                        <span className="filter-heading">
                          <IntlMessages id="sidebar.filter.customerComplaint" />
                          <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                          <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                        </span>
                      )}
                      onTitleClick={(val) => this.loadFilterOptions(val)}
                    >
                      <Menu.Item>
                        <Select
                          options={customerComplaints}
                          showSearch
                          mode="multiple"
                          onChange={this.handleCustomerComplaintChange}
                          allowClear
                        />
                      </Menu.Item>
                    </SubMenu>
                  ) : null
              }
              <SubMenu
                key="fixVersions"
                className={fixVersionSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.fixVersions" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
                onTitleClick={(val) => this.loadFilterOptions(val)}
              >
                <Menu.Item>
                  <Select
                    showSearch
                    mode="multiple"
                    optionLabelProp="value"
                    onChange={this.handleFixVersionChange}
                    allowClear
                  >
                    {SidebarContent.renderVersions(versions)}
                  </Select>
                </Menu.Item>
              </SubMenu>
              {
                superAdmin
                  ? (
                    <SubMenu
                      key="labels"
                      className={labelSelect ? 'select-with-values' : ''}
                      title={(
                        <span className="filter-heading">
                          <IntlMessages id="sidebar.filter.labels" />
                          <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                          <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                        </span>
                      )}
                      onTitleClick={(val) => this.loadFilterOptions(val)}
                    >
                      <Menu.Item>
                        <Select
                          showSearch
                          mode="multiple"
                          allowClear
                          optionLabelProp="value"
                          onChange={this.handleLabelChange}
                        >
                          {SidebarContent.renderLabels(labels)}
                        </Select>
                      </Menu.Item>
                    </SubMenu>
                  ) : null
              }
              {
                superAdmin
                  ? (
                    <SubMenu
                      key="productOwner"
                      className={productOwnerSelect ? 'select-with-values' : ''}
                      title={(
                        <span className="filter-heading">
                          <IntlMessages id="sidebar.filter.productOwner" />
                          <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                          <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                        </span>
                      )}
                      onTitleClick={(val) => this.loadFilterOptions(val)}
                    >
                      <Menu.Item>
                        <Select
                          options={productOwner ? productOwner.filter((po) => !po.disabled) : null}
                          showSearch
                          mode="multiple"
                          onChange={this.handleProductOwnerChange}
                          allowClear
                        />
                      </Menu.Item>
                    </SubMenu>
                  ) : null
              }
              {
                superAdmin
                  ? (
                    <SubMenu
                      key="priority"
                      className={prioritySelect ? 'select-with-values' : ''}
                      title={(
                        <span className="filter-heading">
                          <IntlMessages id="sidebar.filter.priority" />
                          <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                          <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                        </span>
                      )}
                      onTitleClick={(val) => this.loadFilterOptions(val)}
                    >
                      <Menu.Item>
                        <Select
                          showSearch
                          mode="multiple"
                          optionLabelProp="value"
                          onChange={this.handlePriorityChange}
                          allowClear
                        >
                          {SidebarContent.renderPriorities(priorities)}
                        </Select>
                      </Menu.Item>
                    </SubMenu>
                  ) : null
              }
              <SubMenu
                key="requestType"
                className={requestTypeSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.requestType" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
              >
                <Menu.Item>
                  <Search
                    placeholder="Search a request type"
                    allowClear
                    size="middle"
                    loading={requestTypeSearching}
                    onChange={(e) => this.searchRequestType(e.target.value)}
                  />
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="dateCreated"
                className={dateCreatedSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.dateCreated" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
              >
                <Menu.Item>
                  <div className="range-picker-wrapper">
                    <RangePicker
                      onChange={(dates, dateString) => this.handleDateCreated(dates, dateString)}
                      format="YYYY-MM-DD"
                    />
                  </div>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="dateLastModified"
                className={dateUpdateSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.dateLastModified" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
              >
                <Menu.Item>
                  <div className="range-picker-wrapper">
                    <RangePicker
                      onChange={(dates, dateStrings) => this.handleUpdatedDate(dates, dateStrings)}
                      format="YYYY-MM-DD"
                    />
                  </div>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="dateResolved"
                className={dateResolvedSelect ? 'select-with-values' : ''}
                title={(
                  <span className="filter-heading">
                    <IntlMessages id="sidebar.filter.dateResolved" />
                    <FontAwesomeIcon className="icon-plus" icon={faPlus} />
                    <FontAwesomeIcon className="icon-minus" icon={faMinus} />
                  </span>
                )}
              >
                <Menu.Item>
                  <div className="range-picker-wrapper">
                    <RangePicker
                      onChange={(dates, dateStrings) => this.handleResolutionDate(dates, dateStrings)}
                      format="YYYY-MM-DD"
                    />
                  </div>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </CustomScrollbars>
        </div>
      </>
    );
  }
}

SidebarContent.propTypes = {
  loading: PropTypes.bool,
  versions: PropTypes.arrayOf(PropTypes.object).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  productOwner: PropTypes.arrayOf(PropTypes.object).isRequired,
  productTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  roadmapStatus: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerComplaints: PropTypes.arrayOf(PropTypes.object).isRequired,
  CABStatus: PropTypes.arrayOf(PropTypes.object).isRequired,
  orgNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  assignable: PropTypes.arrayOf(PropTypes.object).isRequired,
  reporters: PropTypes.arrayOf(PropTypes.object).isRequired,
  priorities: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  assigneeFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  reporterFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  statusFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchWordFilter: PropTypes.string.isRequired,
  fixVersionFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  productTypeFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  orgNameFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  CABStatusFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  roadmapStatusFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  productOwnerFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  priorityFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  customerComplaintFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateCreatedFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  updatedDateFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  resolutionFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  caseTicketFilter: PropTypes.string.isRequired,
  requestTypeFilter: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onFetchStatuses: PropTypes.func,
  onFetchVersions: PropTypes.func,
  onFetchPriorities: PropTypes.func,
  onFetchCustomFieldOptions: PropTypes.func,
  onFilterIssues: PropTypes.func,
  onFetchAssignees: PropTypes.func,
  onFetchReporters: PropTypes.func,
  onAddFilter: PropTypes.func,
  fetchOwnProfile: PropTypes.func,
  onFetchLabels: PropTypes.func,
};

const mapStateToProps = ({ filterReducer, userReducer }) => ({
  loading: filterReducer.loading,
  versions: filterReducer.versions,
  statuses: filterReducer.statuses,
  priorities: filterReducer.priorities,
  assignable: filterReducer.assignable,
  reporters: filterReducer.reporters,
  issues: filterReducer.issues,
  labels: filterReducer.labels,
  orgNames: filterReducer.customFields.customfield_10115,
  productOwner: filterReducer.customFields.customfield_10131,
  productTypes: filterReducer.customFields.customfield_10135,
  roadmapStatus: filterReducer.customFields.customfield_10136,
  customerComplaints: filterReducer.customFields.customfield_10139,
  CABStatus: filterReducer.customFields.customfield_10140,
  assigneeFilter: filterReducer.filters.assignee,
  reporterFilter: filterReducer.filters.reporter,
  statusFilter: filterReducer.filters.status,
  searchWordFilter: filterReducer.filters.searchWord,
  fixVersionFilter: filterReducer.filters.fixVersion,
  productTypeFilter: filterReducer.filters.productType,
  orgNameFilter: filterReducer.filters.orgName,
  CABStatusFilter: filterReducer.filters.CABStatus,
  roadmapStatusFilter: filterReducer.filters.roadmapStatus,
  productOwnerFilter: filterReducer.filters.productOwner,
  priorityFilter: filterReducer.filters.priority,
  customerComplaintFilter: filterReducer.filters.customerComplaint,
  labelFilter: filterReducer.filters.label,
  caseTicketFilter: filterReducer.filters.caseTicket,
  requestTypeFilter: filterReducer.filters.requestType,
  dateCreatedFilter: filterReducer.filters.dateCreated,
  updatedDateFilter: filterReducer.filters.updatedDate,
  resolutionFilter: filterReducer.filters.resolutionDate,
  page: filterReducer.page,
  pageSize: filterReducer.pageSize,
  superAdmin: userReducer.ownProfile.superAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchStatuses: () => dispatch(fetchStatuses()),
  onFetchVersions: () => dispatch(fetchVersions()),
  onFilterIssues: (filters, startAt, maxResults) => dispatch(filterIssues(filters, startAt, maxResults)),
  onFetchCustomFieldOptions: (fieldKey) => dispatch(fetchCustomFieldOptions(fieldKey)),
  onFetchPriorities: () => dispatch(fetchPriorities()),
  onFetchAssignees: () => dispatch(fetchAssignees()),
  onFetchReporters: () => dispatch(fetchReporters()),
  onFetchLabels: () => dispatch(fetchLabels()),
  onAddFilter: (filter, item) => dispatch(addFilter(filter, item)),
  fetchOwnProfile: () => dispatch(onFetchOwnProfile()),
});

SidebarContent.propTypes = {};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SidebarContent));
