import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import { INVITE_TOKEN } from '@constants/Settings';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '@routes/iTracker/DataTable';

const App = ({ match, location }) => {

  useEffect(() => {
  });

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route exact path="/">
          {location.search.indexOf(INVITE_TOKEN) === -1 ? (
            <Redirect to="/dataTable" />
          ) : null}
        </Route>

        <Route
          exact
          path={`${match.url}dataTable`}
          component={DataTable}
        />
      </Switch>
    </div>
  );
}
App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default App;
