import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootSaga from '@sagas/index';
import createRootReducer from '@reducers';

const createBrowserHistory = require('history').createBrowserHistory;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const saver = (store) => (next) => (action) => {
  setTimeout(() => {
    const stateToSave = store.getState();
    const { columnsReducer } = stateToSave;

    localStorage.setItem('columnsReducer', JSON.stringify(columnsReducer));
  }, 0);

  return next(action);
};

export default function configureStore(preloadedState) {
  const env = process.env.NODE_ENV;
  let store;
  if (env === 'development') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      createRootReducer(history), // root reducer with router state
      preloadedState,
      composeEnhancers(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          saver,
          ...middlewares,
        ),
      ),
    );
  } else {
    store = createStore(
      createRootReducer(history), // root reducer with router state
      preloadedState,
      compose(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          saver,
          ...middlewares,
        ),
      ),
    );
  }

  sagaMiddleware.run(rootSaga);
  return store;
}
