/* eslint-disable no-unused-expressions */
import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  fetchVersionsSuccess,
  fetchStatusesSuccess,
  fetchIssuesSuccess,
  filterIssuesSuccess,
  fetchCustomFieldOptionsSuccess,
  fetchPrioritiesSuccess,
  fetchAssigneesSuccess,
  fetchReportersSuccess,
  fetchLabelsSuccess,
} from '@actions/iTracker/FilterActions';
import {
  GET_VERSIONS,
  GET_STATUSES,
  GET_ISSUES,
  FILTER_ISSUES,
  GET_CUSTOM_FIELD_OPTIONS,
  GET_PRIORITIES,
  GET_ASSIGNEES,
  GET_REPORTERS,
  GET_LABELS,
  PROJECT_ID,
} from '@constants/iTrackerConstants';
import { ENDPOINTS, JIRA } from '@constants/Endpoints';
import { fetchError } from '@actions/Common';
import _ from 'lodash';
// import RestManager from '@util/RestManager';

/**
 * @description Sends GET request via library to fetch versions
 * @returns {Array}
 */
const doFetchVersionsList = async () => {
  const result = [];

  await JIRA.getVersions(PROJECT_ID)
    .then((versions) => result.push(versions))
    .catch((err) => console.log(err));

  return result;
};

/**
 * @description Fetching all versions
 * @returns {void}
 */
function* fetchVersionsList() {
  try {
    const versionsList = yield call(doFetchVersionsList);

    if (versionsList) {
      yield put(fetchVersionsSuccess(versionsList[0]));
    }
  } catch (error) {
    fetchError(error);
  }
}

/**
 * @description Sends GET request to fetch all statuses via library
 * @returns {Array}
 */
const doFetchStatuses = async () => {
  const result = [];

  await JIRA.listStatus()
    .then((statuses) => result.push(statuses))
    .catch((err) => console.log(err));

  return result;
};

/**
 * @description Fetching all statuses
 * @returns {void}
 */
function* fetchStatusesList() {
  try {
    const statusesList = yield call(doFetchStatuses);
    const statusesWithoutRepetition = _.uniqBy(statusesList[0], 'name');

    if (statusesList) {
      yield put(fetchStatusesSuccess(statusesWithoutRepetition));
    }
  } catch (error) {
    fetchError(error);
  }
}

/**
 * @description Sends GET request to fetch priorities
 * @returns {Array}
 */
const doFetchPriorities = async () => {
  const result = [];

  await JIRA.listPriorities()
    .then((response) => result.push(response))
    .catch((err) => console.log(err));

  return result;
};

/**
 * @description Fetching priorities list
 * @returns {void}
 */
function* fetchPrioritiesList() {
  try {
    const result = yield call(doFetchPriorities);

    if (result) {
      yield put(fetchPrioritiesSuccess(result[0]));
    }
  } catch (error) {
    fetchError(error);
  }
}

/**
 * @description Sends GET request to fetch issues
 * @param {Number} startAt
 * @param {Number} resultsPerPage
 * @returns {Array}
 */
const doFetchIssues = async (startAt, maxResults) => {
  const result = [];

  await JIRA.searchJira(`project=${PROJECT_ID}`, { maxResults, startAt })
    .then((issues) => result.push(issues))
    .catch((err) => console.log(err));

  return result;
};

/**
 * @description Fetching all issues list
 * @param {Number} startAt
 * @returns {void}
 */
function* fetchSearchedIssues({ startAt, maxResults }) {
  try {
    const totalResults = yield call(doFetchIssues, startAt, maxResults);

    if (totalResults) {
      yield put(fetchIssuesSuccess(totalResults));
    }
  } catch (error) {
    fetchError(error);
  }
}

/**
 * @description Helper to adapt selected filters for search query
 * @param {Array} filters
 * @returns {Array}
 */
const adaptFilters = (filters) => {
  let adaptedFilters = '';
  filters.forEach((filter) => {
    if (filter) {
      adaptedFilters += `${`"${filter}",`}`;
    }
  });
  adaptedFilters = adaptedFilters.substring(0, adaptedFilters.length - 1);

  return adaptedFilters;
};

/**
 * @description Sends POST request via library for filtered issues
 * @param {Object} filters
 * @param {Number} startAt
 * @returns {Array}
 */
const doFetchFilterIssues = async (filters, startAt, maxResults) => {
  const result = [];

  let jql = `project=${PROJECT_ID}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const filter in filters) {
    if (filters[filter].length > 0) {
      (filter === 'status') ? jql += `&status IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'reporter') ? jql += `&reporter IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'assignee') ? jql += `&assignee IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'searchWord' && filters[filter].length > 2)
        ? jql += `&(summary ~ '${filters[filter]}' OR description ~ '${filters[filter]}')`
        : jql += '';
      (filter === 'fixVersion') ? jql += `&fixVersion IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'productType')
        ? jql += `&"Product Type[Select List (multiple choices)]" IN (${adaptFilters(filters[filter])})`
        : jql += '';
      (filter === 'orgName')
        ? jql += `&"Org Name[Select List (multiple choices)]" IN (${adaptFilters(filters[filter])})`
        : jql += '';
      (filter === 'CABStatus')
        ? jql += `&"CAB Status[Select List (multiple choices)]" IN (${adaptFilters(filters[filter])})`
        : jql += '';
      (filter === 'roadmapStatus') ? jql += `&"Roadmap Status[Dropdown]" IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'priority') ? jql += `&priority IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'productOwner') ? jql += `&"Product Owner[Dropdown]" IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'customerComplaint') ? jql += `&"Customer Complaint[Checkboxes]" IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'label') ? jql += `&labels IN (${adaptFilters(filters[filter])})` : jql += '';
      (filter === 'caseTicket' && filters[filter].length > 2)
        ? jql += `&"Case Ticket #[Paragraph]" ~ ${filters[filter]}`
        : jql += '';
      (filter === 'requestType' && filters[filter].length > 2)
        ? jql += `&"Request Type" IN ("${filters[filter]}")`
        : jql += '';
      (filter === 'dateCreated' && filters[filter][0] && filters[filter][1])
        ? jql += `&created >= ${filters[filter][0]} AND created <= ${filters[filter][1]}`
        : jql += '';
      (filter === 'updatedDate' && filters[filter][0] && filters[filter][1])
        ? jql += `&updated >= ${filters[filter][0]} AND updated <= ${filters[filter][1]}`
        : jql += '';
      (filter === 'resolutionDate' && filters[filter][0] && filters[filter][1])
        ? jql += `&resolved >= ${filters[filter][0]} AND resolved <= ${filters[filter][1]}`
        : jql += '';
    }
  }

  await JIRA.searchJira(jql, { maxResults, startAt, validateQuery: 'none' })
    .then((issues) => result.push(issues))
    .catch((err) => console.log(err));

  return result;
};

/**
 * @description Fetching filtered issues depending on filters
 * @param {String} status
 * @param {String} reporter
 * @returns {void}
 */
function* fetchFilterIssues({ filters, startAt, maxResults }) {
  try {
    const result = yield call(doFetchFilterIssues, filters, startAt, maxResults);

    if (result) {
      yield put(filterIssuesSuccess(result));
    }
  } catch (error) {
    fetchError(error);
  }
}

const doFetchLabels = async () => {
  const result = [];

  await JIRA.doRequest(JIRA.makeRequestHeader(
    JIRA.makeUri({
      pathname: ENDPOINTS.labels,
    }),
  )).then((response) => result.push(response))
    .catch((error) => console.log(error));

  return result;
};

function* fetchLabelsList() {
  try {
    const result = yield call(doFetchLabels);

    if (result) {
      yield put(fetchLabelsSuccess(result[0].values));
    }
  } catch (error) {
    fetchError(error);
  }
}

/**
 * @description Sends GET request to get assignees
 * @returns {Array}
 */
const doFetchAssignees = async () => {
  const result = [];

  await JIRA.doRequest(JIRA.makeRequestHeader(
    JIRA.makeUri({
      pathname: ENDPOINTS.assignable,
    }),
  )).then((response) => result.push(response))
    .catch((error) => console.log(error));

  return result;
};

/**
 * @description Fetching assignees list
 * @returns {void}
 */
function* fetchAssigneesList() {
  try {
    const result = yield call(doFetchAssignees);

    if (result) {
      yield put(fetchAssigneesSuccess(result[0]));
    }
  } catch (error) {
    fetchError(error);
  }
}

/**
 * @description Sends GET request to get reporters
 * @returns {Array}
 */
const doFetchReporters = async () => {
  const result = [];

  await JIRA.doRequest(JIRA.makeRequestHeader(
    JIRA.makeUri({
      pathname: ENDPOINTS.reportersList,
    }),
  )).then((response) => result.push(response))
    .catch((error) => console.log(error));

  return result;
};

/**
 * @description Fetching reporters list
 * @returns {void}
 */
function* fetchReportersList() {
  try {
    const result = yield call(doFetchReporters);
    const reporters = _.uniqBy(result[0], 'displayName');

    if (result) {
      yield put(fetchReportersSuccess(reporters));
    }
  } catch (error) {
    fetchError(error);
  }
}

/**
 * @description Sends GET request to get custom field id
 * @param {String} customField
 * @returns {Array}
 */
const doFetchCustomFieldId = async (customField) => {
  const result = [];

  await JIRA.doRequest(JIRA.makeRequestHeader(
    JIRA.makeUri({
      pathname: ENDPOINTS.getCustomFieldId(customField),
    }),
  )).then((response) => result.push(response))
    .catch((err) => console.log(err));

  return result;
};

/**
 * @description Sends GET request to get custom field options
 * @param {String} field
 * @param {Number} id
 * @returns {Array}
 */
const doFetchCustomFieldOptions = async (field, id) => {
  const result = [];

  await JIRA.doRequest(JIRA.makeRequestHeader(
    JIRA.makeUri({
      pathname: ENDPOINTS.getCustomFieldOptions(field, id),
    }),
  )).then((response) => result.push(response))
    .catch((err) => console.log(err));

  return result;
};

/**
 * @description Fetching options for custom field by its id
 * @param {String} field
 * @returns {void}
 */
function* onFetchCustomFieldId({ field }) {
  try {
    const result = yield call(doFetchCustomFieldId, field);

    if (result) {
      const fieldId = result[0].values[0].id;
      const options = yield call(doFetchCustomFieldOptions, field, fieldId);

      if (options) {
        yield put(fetchCustomFieldOptionsSuccess(options, field));
      }
    }
  } catch (err) {
    fetchError(err);
  }
}

export function* actionsWatcher() {
  yield takeEvery(GET_VERSIONS, fetchVersionsList);
  yield takeEvery(GET_STATUSES, fetchStatusesList);
  yield takeEvery(GET_ISSUES, fetchSearchedIssues);
  yield takeEvery(FILTER_ISSUES, fetchFilterIssues);
  yield takeEvery(GET_CUSTOM_FIELD_OPTIONS, onFetchCustomFieldId);
  yield takeEvery(GET_PRIORITIES, fetchPrioritiesList);
  yield takeEvery(GET_ASSIGNEES, fetchAssigneesList);
  yield takeEvery(GET_REPORTERS, fetchReportersList);
  yield takeEvery(GET_LABELS, fetchLabelsList);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
