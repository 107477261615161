import {
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_OWN_PROFILE_SUCCESS,
} from '@constants/iTrackerConstants';

const INIT_STATE = {
  ownProfile: {
    loading: false,
  },
};

export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case FETCH_OWN_PROFILE_REQUEST:
      newState = { ownProfile: { loading: true } };
      return { ...state, ...newState };

    case FETCH_OWN_PROFILE_SUCCESS:
      newState = { ownProfile: { ...action.payload, loading: false } };
      return { ...state, ...newState };

    default:
      return state;
  }
};
