import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  onFetchOwnProfileSuccess,
} from '@actions/iTracker/UserActions';
import {
  FETCH_OWN_PROFILE_REQUEST,
} from '@constants/iTrackerConstants';
import { ENDPOINTS } from '@constants/Endpoints';
import { fetchError } from '@actions/Common';
import RestManager from '@util/RestManager';

const doFetchOwnProfile = async () => RestManager.request(`${ENDPOINTS.ownProfile}`);

function* fetchOwnProfile() {
  try {
    const profile = yield call(doFetchOwnProfile);

    if (profile) {
      yield put(onFetchOwnProfileSuccess({ ...profile }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_OWN_PROFILE_REQUEST, fetchOwnProfile);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
