import {
  GET_VERSIONS,
  GET_VERSIONS_SUCCESS,
  GET_STATUSES,
  GET_STATUSES_SUCCESS,
  GET_ISSUES,
  GET_ISSUES_SUCCESS,
  FILTER_ISSUES,
  FILTER_ISSUES_SUCCESS,
  GET_CUSTOM_FIELD_OPTIONS,
  GET_CUSTOM_FIELD_OPTIONS_SUCCESS,
  GET_PRIORITIES,
  GET_PRIORITIES_SUCCESS,
  GET_ASSIGNEES,
  GET_ASSIGNEES_SUCCESS,
  GET_REPORTERS,
  GET_REPORTERS_SUCCESS,
  GET_LABELS,
  GET_LABELS_SUCCESS,
  ADD_FILTER,
  SET_PAGE_NUMBER,
} from '@constants/iTrackerConstants';

const INIT_STATE = {
  versions: [],
  statuses: [],
  issues: [],
  issuesCount: 0,
  page: 1,
  pageSize: 10,
  priorities: [],
  assignable: [],
  reporters: [],
  labels: [],
  customFields: {
    customfield_10115: null,
    customfield_10131: null,
    customfield_10135: null,
    customfield_10136: null,
    customfield_10139: null,
    customfield_10140: null,
  },
  filters: {
    reporter: [],
    assignee: [],
    status: [],
    searchWord: '',
    fixVersion: [],
    productType: [],
    orgName: [],
    CABStatus: [],
    roadmapStatus: [],
    productOwner: [],
    priority: [],
    customerComplaint: [],
    label: [],
    caseTicket: '',
    requestType: '',
    dateCreated: [],
    updatedDate: [],
    resolutionDate: [],
  },
  loading: false,
};

/**
 * @description Fill redux state with custom options
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const onFetchCustomFieldOptions = (state, action) => {
  let newState = null;

  for (const [key] of Object.entries(state.customFields)) {
    if (key.localeCompare() === action.field.localeCompare()) {
      newState = {
        ...state,
        loading: false,
        customFields: {
          ...state.customFields,
          [action.field]: action.payload[0].values,
        },
      };
    }
  }

  return { ...state, ...newState };
};

/**
 * @description Fill redux state with filter
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const setFilterField = (state, action) => {
  let newState = null;

  for (const [key] of Object.entries(state.filters)) {
    if (key.localeCompare() === action.item.localeCompare()) {
      newState = {
        ...state,
        loading: false,
        filters: {
          ...state.filters,
          [action.item]: action.filters,
        },
      };
    }
  }

  return { ...state, ...newState };
};

/**
 * @description Filter reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case GET_VERSIONS: {
      return { ...state };
    }

    case GET_VERSIONS_SUCCESS: {
      newState = { versions: action.payload };

      return { ...state, ...newState };
    }

    case GET_STATUSES: {
      return { ...state };
    }

    case GET_STATUSES_SUCCESS: {
      newState = { statuses: action.payload };

      return { ...state, ...newState };
    }

    case GET_ISSUES: {
      return { ...state, loading: true };
    }

    case GET_ISSUES_SUCCESS: {
      newState = {
        issues: action.payload[0].issues,
        issuesCount: action.payload[0].total,
        loading: false,
      };

      return { ...state, ...newState };
    }

    case FILTER_ISSUES: {
      return { ...state, loading: true };
    }

    case FILTER_ISSUES_SUCCESS: {
      newState = {
        issues: action.payload[0].issues,
        issuesCount: action.payload[0].total,
        loading: false,
      };

      return { ...state, ...newState };
    }

    case GET_CUSTOM_FIELD_OPTIONS: {
      return { ...state };
    }

    case GET_CUSTOM_FIELD_OPTIONS_SUCCESS: {
      return onFetchCustomFieldOptions(state, action);
    }

    case GET_PRIORITIES: {
      return { ...state };
    }

    case GET_PRIORITIES_SUCCESS: {
      newState = { priorities: action.payload };
      return { ...state, ...newState };
    }

    case GET_ASSIGNEES: {
      return { ...state };
    }

    case GET_ASSIGNEES_SUCCESS: {
      newState = { assignable: action.payload };

      return { ...state, ...newState };
    }

    case GET_REPORTERS: {
      return { ...state };
    }

    case GET_REPORTERS_SUCCESS: {
      newState = { reporters: action.payload };

      return { ...state, ...newState };
    }

    case GET_LABELS: {
      return { ...state };
    }

    case GET_LABELS_SUCCESS: {
      newState = { labels: action.payload };

      return { ...state, ...newState };
    }

    case ADD_FILTER: {
      return setFilterField(state, action);
    }

    case SET_PAGE_NUMBER: {
      newState = { loading: false, page: action.page, pageSize: action.size };

      return { ...state, ...newState };
    }

    default:
      return state;
  }
};
