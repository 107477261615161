import RestManager from '@util/RestManager';
import {
  ACCESS_TOKEN_KEY,
  IMPERSONATE_TOKEN,
  REFRESH_TOKEN_KEY,
  CURRENT_USER_KEY,
} from '@constants/Settings';

const UheHelper = {
  /**
   * @param  {String} filter
   * @param  {String} sorting
   * @param  {String} endpoint
   * @description Get endpoint url for data export
   * @return {String}
   */
  getCsvUrl: (filter, sorting, endpointUrl) => {
    const token = RestManager.getToken();

    const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
    const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';

    const tokenParam = `${filterQueryString || sortingQueryString ? `&token=${token}` : `token=${token}`}`;
    return `${endpointUrl}${filterQueryString}${sortingQueryString}${tokenParam}`;
  },
};

/**
 * @param {string} location Fallback location to return to
 * @param history History from react-router from props
 * @param lastLocation Last location from react-router-last-location from props
 * @returns {function(): void} Action which pushes to state
 * or calls goBack whether the user has been on App page or not
 */
export const goBackTo = (location, { history, lastLocation } = {}) => () => {
  if (lastLocation?.pathname === location) {
    history.goBack();
  } else {
    history.push(location);
  }
};
UheHelper.goBackTo = goBackTo;

/**
 * @description Utility singleton for easy case transformation
 * @type {{
 *  fromUpperSnakeToPascalCase(string): string
 * }}
 */
export const CaseHelper = {
  /**
   * @param {string} value
   * @returns {string}
   */
  fromUpperSnakeToPascalCase(value) {
    return value.split('_')
      .map(([...y]) => y.shift().toUpperCase() + y.join('').toLowerCase())
      .join('');
  },
  fromPascalToCamelCase(value) {
    return [...value].map((char, index) => index === 0 ? char.toLowerCase() : char).join('');
  },
  fromSpaceToCamelCase(value) {
    return value
      .split(' ')
      .map(([...word], index) => index === 0
        ? word.join('').toLowerCase()
        : word.shift().toUpperCase() + word.join('').toLowerCase())
      .join('');
  },
};
UheHelper.CaseHelper = CaseHelper;


/**
 * @description Makes Type Ahead of Filter Input Case Insensitive
 * @param {string} inputValue
 * @param {Object} path
 * @returns {Object}
 */
export const cascaderSearchFilter = (inputValue, path) => path.some((option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

export default UheHelper;
