import React, { useEffect } from 'react';
import { Layout, Input } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import { switchLanguage, toggleCollapsedSideNav } from '@actions/Setting';
import Auxiliary from 'util/Auxiliary';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from '@constants/ThemeSetting';
import UserProfile from '../Sidebar/UserProfile';

const { Search } = Input;
const { Header } = Layout;
const LANG_SELECTOR_ENABLED = true;

const Topbar = (props) => {
  const { locale, width, navCollapsed, navStyle } = useSelector(
    ({ settings }) => settings
  );

  const dispatch = useDispatch();

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-1">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </div>
        ) : null}
      <Link to="/" className="logo-sm gx-d-block gx-d-lg-none gx-pointer gx-d-flex gx-justify-content-start">
        <img alt="" className="logo-sm" src={require("assets/images/c-logo.png")} />
      </Link>
    </Header>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(Topbar);
