import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  getTableColumns, toggleTableColumn,
} from '@actions/iTracker/ColumnsActions';
import {
  fetchIssues, addFilter, filterIssues, setPage,
} from '@actions/iTracker/FilterActions';
import { onFetchOwnProfile } from '@actions/iTracker/UserActions';
import ReactDataGrid from 'react-data-grid';
import {
  Checkbox, Pagination, Input, Spin, Popover,
} from 'antd';
import CustomSimpleCellFormatter from '../../containers/CustomSimpleCellFormatter';

const { Search } = Input;

/**
 * @description Renders Table component
 */
class DataTable extends Component {
  /**
   * @description Adapts response for fix versions
   * @param {Array} data
   * @returns {Array}
   */
  static adaptFixVersions(data = []) {
    const list = [];

    data.forEach((value) => {
      list.push(value.name);
    });

    return list;
  }

  static adaptLabels(data = []) {
    const list = [];

    data.forEach((item, index) => {
      if (index !== (data.length - 1)) {
        list.push(`${item}, `);
      } else {
        list.push(item);
      }
    });

    return list;
  }

  /**
   * @description Adapts response for product types
   * @param {Array} data
   * @returns {Array}
   */
  static adaptListOfItems(data = []) {
    const list = [];

    data.forEach((item, index) => {
      if (index !== (data.length - 1)) {
        list.push(`${item.value}, `);
      } else {
        list.push(item.value);
      }
    });

    return list;
  }

  /**
   * @description Adapts data for the data table
   * @param {Array} data
   * @returns {Array}
   */
  static dataAdapter(data = []) {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        keyID: value.key,
        summary: value.fields
          ? (
            <Popover
              placement="topLeft"
              content={value.fields.summary}
            >
              {value.fields.summary}
            </Popover>
          )
          : null,
        description: value.fields
          ? (
            <Popover
              placement="topLeft"
              content={value.fields.description}
            >
              {value.fields.description}
            </Popover>
          )
          : null,
        productType: value.fields && value.fields.customfield_10135
          ? (
            <Popover
              placement="topLeft"
              content={DataTable.adaptListOfItems(value.fields.customfield_10135)}
            >
              {DataTable.adaptListOfItems(value.fields.customfield_10135)}
            </Popover>
          )
          : null,
        organizationName: value.fields && value.fields.customfield_10115
          ? (
            <Popover
              placement="topLeft"
              content={DataTable.adaptListOfItems(value.fields.customfield_10115)}
            >
              {DataTable.adaptListOfItems(value.fields.customfield_10115)}
            </Popover>
          )
          : null,
        caseTicket: value.fields && value.fields.customfield_10116
          ? (
            <Popover
              placement="topLeft"
              content={value.fields.customfield_10116}
            >
              {value.fields.customfield_10116}
            </Popover>
          )
          : null,
        reporter: value.fields && value.fields.reporter ? value.fields.reporter.displayName : null,
        assignee: value.fields && value.fields.assignee ? value.fields.assignee.displayName : null,
        CABStatus: value.fields && value.fields.customfield_10140
          ? value.fields.customfield_10140[0].value
          : null,
        roadmapStatus: value.fields && value.fields.customfield_10136
          ? value.fields.customfield_10136.value
          : null,
        ticketStatus: value.fields && value.fields.status ? value.fields.status.name : null,
        customerComplaint: value.fields && value.fields.customfield_10139
          ? value.fields.customfield_10139[0].value
          : null,
        fixVersions: value.fields && value.fields.fixVersions
          ? DataTable.adaptFixVersions(value.fields.fixVersions)
          : null,
        labels: value.fields && value.fields.labels
          ? (
            <Popover
              placement="topLeft"
              content={DataTable.adaptLabels(value.fields.labels)}
            >
              {DataTable.adaptLabels(value.fields.labels)}
            </Popover>
          )
          : null,
        productOwner: value.fields && value.fields.customfield_10131
          ? value.fields.customfield_10131.value
          : null,
        priority: value.fields && value.fields.priority ? value.fields.priority.name : null,
        requestType: value.fields.customfield_10010 && value.fields.customfield_10010.requestType
          ? value.fields.customfield_10010.requestType.name
          : null,
        dateCreated: value.fields && value.fields.created
          ? Moment(value.fields.created).tz('America/New_York').format('DD/MM/YYYY, h:mm:ss A')
          : null,
        dateLastModified: value.fields && value.fields.updated
          ? Moment(value.fields.updated).tz('America/New_York').format('DD/MM/YYYY, h:mm:ss A')
          : null,
        dateResolved: value.fields && value.fields.resolutiondate
          ? Moment(value.fields.resolutiondate).tz('America/New_York').format('DD/MM/YYYY, h:mm:ss A')
          : null,
      });
    });

    return adaptedData;
  }

  constructor(props) {
    super(props);

    this.state = {
      issuesList: [],
      currentPage: 1,
      searching: false,
      itemsPerPage: 10,
    };

    this.columns = [];
    this.columnsAll = [];

    this.tableKeys = [
      'keyID',
      'summary',
      'description',
      'productType',
      'organizationName',
      'caseTicket',
      'reporter',
      'assignee',
      'CABStatus',
      'roadmapStatus',
      'ticketStatus',
      'customerComplaint',
      'fixVersions',
      'labels',
      'productOwner',
      'priority',
      'requestType',
      'dateCreated',
      'dateLastModified',
      'dateResolved',
    ];
    const { intl } = this.props;
    this.tableKeys.forEach((value, index) => {
      if (value !== 'caseTicket' && value !== 'assignee' && value !== 'roadmapStatus' && value !== 'ticketStatus'
      && value !== 'customerComplaint' && value !== 'labels' && value !== 'productOwner' && value !== 'priority') {
        this.columns.push({
          id: index,
          name: intl.formatMessage({ id: `table.heading.${value}` }),
          key: value,
          visible: true,
          width: 200,
          formatter: CustomSimpleCellFormatter,
        });
      }

      this.columnsAll.push({
        id: index,
        name: intl.formatMessage({ id: `table.heading.${value}` }),
        key: value,
        visible: true,
        width: 200,
        formatter: CustomSimpleCellFormatter,
      });
    });

    this.dataTable = React.createRef();

    this.renderToggleColumns = this.renderToggleColumns.bind(this);
    this.toggleColumn = this.toggleColumn.bind(this);
    this.handleOnPageChange = this.handleOnPageChange.bind(this);
    this.filterBySearch = this.filterBySearch.bind(this);
    this.handleFilteredItems = this.handleFilteredItems.bind(this);
    this.searchOnChange = this.searchOnChange.bind(this);

    this.delayedSearchQuery = _.debounce(this.filterBySearch, 1000);
  }

  componentDidMount() {
    const { onFetchIssues } = this.props;
    const { currentPage, itemsPerPage } = this.state;

    const getColumnsFromStorage = JSON.parse(localStorage.getItem('columnsReducer'));

    if (getColumnsFromStorage) {
      const persistedColumns = getColumnsFromStorage.columns;
      if (persistedColumns.length > 0) {
        this.formatColumns(persistedColumns);
      }
    }

    onFetchIssues(currentPage * itemsPerPage - itemsPerPage, itemsPerPage);
  }

  componentDidUpdate(prevProps) {
    const { issues, superAdmin, onGetTableColumns } = this.props;

    const getColumnsFromStorage = JSON.parse(localStorage.getItem('columnsReducer'));

    if (prevProps.superAdmin !== superAdmin) {
      if (getColumnsFromStorage) {
        const persistedColumns = getColumnsFromStorage.columns;
        if (persistedColumns.length > 0) {
          this.formatColumns(persistedColumns);
        } else {
          const columnsToShow = superAdmin ? this.columnsAll : this.columns;

          onGetTableColumns(columnsToShow);
        }
      } else {
        const columnsToShow = superAdmin ? this.columnsAll : this.columns;

        onGetTableColumns(columnsToShow);
      }
    }

    if (prevProps.issues.length === 0 && issues.length > 0) {
      this.setState({ issuesList: issues });
    }
    if (JSON.stringify(prevProps.issues) !== JSON.stringify(issues)) {
      this.setState({ issuesList: issues });
    }
  }

  /**
   * @description Formats columns from local storage to prevent showing default tooltip
   * @param {Array} columnsList
   * @returns {void}
   */
  formatColumns(columnsList) {
    const formatted = [];
    const { onGetTableColumns } = this.props;
    columnsList.map(
      (column) => formatted.push({ ...column, formatter: CustomSimpleCellFormatter }),
    );

    onGetTableColumns(formatted);
  }

  /**
   * @description Toggles columns in table
   * @param {String} key
   * @returns {void}
   */
  async toggleColumn(key) {
    const { columns, onToggleTableColumn } = this.props;

    const selectedColumn = columns.find((column) => column.key === key);

    await onToggleTableColumn(selectedColumn);
  }

  /**
   * @description Filter issues by selected filters and searched values
   * @param {Number} pageSize
   * @returns {void}
   */
  handleFilteredItems(pageSize) {
    const {
      onFilterIssues,
      onFetchIssues,
      reporterFilter,
      assigneeFilter,
      statusFilter,
      searchWordFilter,
      fixVersionFilter,
      productTypeFilter,
      orgNameFilter,
      CABStatusFilter,
      roadmapStatusFilter,
      productOwnerFilter,
      priorityFilter,
      customerComplaintFilter,
      labelFilter,
      caseTicketFilter,
      requestTypeFilter,
      dateCreatedFilter,
      updatedDateFilter,
      resolutionFilter,
      currentPage,
    } = this.props;

    const filters = {
      status: statusFilter,
      reporter: reporterFilter,
      assignee: assigneeFilter,
      searchWord: searchWordFilter,
      fixVersion: fixVersionFilter,
      productType: productTypeFilter,
      orgName: orgNameFilter,
      CABStatus: CABStatusFilter,
      roadmapStatus: roadmapStatusFilter,
      productOwner: productOwnerFilter,
      priority: priorityFilter,
      customerComplaint: customerComplaintFilter,
      label: labelFilter,
      caseTicket: caseTicketFilter,
      requestType: requestTypeFilter,
      dateCreated: dateCreatedFilter,
      updatedDate: updatedDateFilter,
      resolutionDate: resolutionFilter,
    };

    const hasFilters = Object.keys(filters).some((key) => filters[key]);

    if (hasFilters) {
      onFilterIssues(filters, currentPage * pageSize - pageSize, pageSize);
    } else {
      onFetchIssues(currentPage * pageSize - pageSize);
    }
  }

  /**
   * @description Changes state on page change
   * @param {Number} page
   * @param {Number} size
   * @returns {void}
   */
  async handleOnPageChange(page, size) {
    const { onSetPage } = this.props;

    await onSetPage(page, size);
    this.handleFilteredItems(size);
    this.setState({
      currentPage: page,
      searching: false,
      itemsPerPage: size,
    });
  }

  /**
   * @description Search by word for summary and description
   * @param {String} word
   * @returns {void}
   */
  filterBySearch(word) {
    const {
      onAddFilter,
      onFilterIssues,
      statusFilter,
      assigneeFilter,
      reporterFilter,
      fixVersionFilter,
      productTypeFilter,
      orgNameFilter,
      CABStatusFilter,
      roadmapStatusFilter,
      productOwnerFilter,
      priorityFilter,
      customerComplaintFilter,
      labelFilter,
      caseTicketFilter,
      requestTypeFilter,
      dateCreatedFilter,
      updatedDateFilter,
      resolutionFilter,
      currentPage,
      pageSize,
    } = this.props;

    const filters = {
      status: statusFilter,
      assignee: assigneeFilter,
      reporter: reporterFilter,
      fixVersion: fixVersionFilter,
      productType: productTypeFilter,
      orgName: orgNameFilter,
      CABStatus: CABStatusFilter,
      roadmapStatus: roadmapStatusFilter,
      productOwner: productOwnerFilter,
      priority: priorityFilter,
      customerComplaint: customerComplaintFilter,
      label: labelFilter,
      caseTicket: caseTicketFilter,
      requestType: requestTypeFilter,
      dateCreated: dateCreatedFilter,
      updatedDate: updatedDateFilter,
      resolutionDate: resolutionFilter,
      searchWord: word,
    };
    this.setState({ searching: false });
    onAddFilter(word, 'searchWord');
    onFilterIssues(filters, currentPage * pageSize - pageSize, pageSize);
  }

  /**
   * @description Triggers debouncer for searching
   * @param {String} value
   * @return {void}
   */
  searchOnChange(value) {
    this.delayedSearchQuery(value);
    this.setState({ searching: true });
  }

  /**
   * @description Renders toggle columns checkboxes
   * @returns {JSX}
   */
  renderToggleColumns() {
    const { columns } = this.props;

    const JSXToReturn = columns.map((column) => (
      <Checkbox
        key={column.id}
        onClick={() => this.toggleColumn(column.key)}
        checked={column.visible}
        className="column-checkbox"
      >
        {column.name}
      </Checkbox>
    ));

    return JSXToReturn;
  }

  render() {
    const {
      issuesList, currentPage, searching, itemsPerPage,
    } = this.state;
    const { columns, issuesCount, loading } = this.props;

    return (
      <>
        <div>
          <div className="main-search">
            <Search
              placeholder="Search a ticket"
              allowClear
              size="large"
              loading={searching}
              onChange={(e) => this.searchOnChange(e.target.value)}
            />
          </div>
          <div className="toggle-checkboxes">
            {this.renderToggleColumns()}
          </div>
          <div>
            <div>
              <div className="matching-results">
                <span>
                  Matching results:
                  {' '}
                  {issuesCount}
                </span>
              </div>
              <div className={`${loading ? 'table-loading' : ''}`}>
                <Spin
                  spinning={loading}
                >
                  <div className={`${(itemsPerPage === 20) ? 'large-table' : 'small-table'}`}>
                    <ReactDataGrid
                      ref={this.dataTable}
                      columns={columns.filter((c) => c.visible)}
                      rowGetter={(i) => DataTable.dataAdapter(issuesList)[i]}
                      rowsCount={itemsPerPage}
                      rows={DataTable.dataAdapter(issuesList)}
                      minHeight={(itemsPerPage === 10) ? 400 : 747}
                      overScan={{
                        colsStart: 20, colsEnd: 20, rowsStart: 20, rowsEnd: 20,
                      }}
                    />
                  </div>
                </Spin>
              </div>
            </div>
            <div className="pagination">
              <Pagination
                total={issuesCount}
                defaultCurrent={1}
                current={currentPage}
                pageSizeOptions={[10, 20]}
                showSizeChanger
                onChange={(page, size) => this.handleOnPageChange(page, size)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

DataTable.propTypes = {
  onAddFilter: PropTypes.func,
  onFilterIssues: PropTypes.func,
  statusFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  assigneeFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  reporterFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  fixVersionFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  productTypeFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  orgNameFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  CABStatusFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  roadmapStatusFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  productOwnerFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  priorityFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  customerComplaintFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  caseTicketFilter: PropTypes.string.isRequired,
  searchWordFilter: PropTypes.string.isRequired,
  requestTypeFilter: PropTypes.string.isRequired,
  dateCreatedFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  updatedDateFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  resolutionFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentPage: PropTypes.number.isRequired,
  intl: PropTypes.object,
  columns: PropTypes.array,
  onToggleTableColumn: PropTypes.func,
  onGetTableColumns: PropTypes.func,
  onFetchIssues: PropTypes.func,
  issues: PropTypes.arrayOf(PropTypes.object).isRequired,
  issuesCount: PropTypes.number.isRequired,
  onSetPage: PropTypes.func,
  superAdmin: PropTypes.bool,
  fetchOwnProfile: PropTypes.func,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ columnsReducer, filterReducer, userReducer }) => {
  const { issues } = filterReducer || { issues: [] };
  const { columns } = columnsReducer || { columns: [] };
  const statusFilter = filterReducer.filters.status;
  const assigneeFilter = filterReducer.filters.assignee;
  const reporterFilter = filterReducer.filters.reporter;
  const fixVersionFilter = filterReducer.filters.fixVersion;
  const productTypeFilter = filterReducer.filters.productType;
  const orgNameFilter = filterReducer.filters.orgName;
  const CABStatusFilter = filterReducer.filters.CABStatus;
  const roadmapStatusFilter = filterReducer.filters.roadmapStatus;
  const productOwnerFilter = filterReducer.filters.productOwner;
  const priorityFilter = filterReducer.filters.priority;
  const customerComplaintFilter = filterReducer.filters.customerComplaint;
  const searchWordFilter = filterReducer.filters.searchWord;
  const labelFilter = filterReducer.filters.label;
  const requestTypeFilter = filterReducer.filters.requestType;
  const caseTicketFilter = filterReducer.filters.caseTicket;
  const dateCreatedFilter = filterReducer.filters.dateCreated;
  const updatedDateFilter = filterReducer.filters.updatedDate;
  const resolutionFilter = filterReducer.filters.resolutionDate;
  const { issuesCount } = filterReducer;
  const currentPage = filterReducer.page;
  const pageSize = filterReducer.pageSize;
  const { superAdmin } = userReducer.ownProfile;
  const { loading } = filterReducer;

  return {
    issues,
    columns,
    statusFilter,
    assigneeFilter,
    reporterFilter,
    fixVersionFilter,
    productTypeFilter,
    orgNameFilter,
    CABStatusFilter,
    roadmapStatusFilter,
    productOwnerFilter,
    priorityFilter,
    customerComplaintFilter,
    searchWordFilter,
    labelFilter,
    caseTicketFilter,
    requestTypeFilter,
    dateCreatedFilter,
    updatedDateFilter,
    resolutionFilter,
    issuesCount,
    currentPage,
    pageSize,
    superAdmin,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetTableColumns: (columns) => dispatch(getTableColumns(columns)),
  onToggleTableColumn: (column) => dispatch(toggleTableColumn(column)),
  onFetchIssues: (startAt, maxResults) => dispatch(fetchIssues(startAt, maxResults)),
  onFilterIssues:
    (filter, startAt, maxResults) => dispatch(filterIssues(filter, startAt, maxResults)),
  onAddFilter: (filter, item) => dispatch(addFilter(filter, item)),
  onSetPage: (page, size) => dispatch(setPage(page, size)),
  fetchOwnProfile: () => dispatch(onFetchOwnProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(DataTable)));
