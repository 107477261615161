import React from 'react';
import PropTypes from 'prop-types';

class CustomSimpleCellFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType(
      [PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool, PropTypes.array],
    ),
  };

  shouldComponentUpdate(nextProps) {
    const { value } = this.props;

    return nextProps.value !== value;
  }

  render() {
    const { value } = this.props;

    return (
      <div>{value}</div>
    );
  }
}

export default CustomSimpleCellFormatter;
