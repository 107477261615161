import { PROJECT_ID } from '@constants/iTrackerConstants';
import JiraApi from 'jira-client';

export const BASE_URL = '/api';

export const JIRA = new JiraApi({
  protocol: process.env.REACT_APP_BUILD_ENV === 'local' ? 'http' : 'https',
  port: process.env.REACT_APP_BUILD_ENV === 'local' ? 3000 : undefined,
  host: window.location.hostname,
  username: 'jira-svc@caregility.com',
  password: 'CnvK7LbouREjUJiB78Yx5FA0',
  apiVersion: '2',
  strictSSL: false,
});

export const ENDPOINTS = {
  assignable: `/user/assignable/search?project=${PROJECT_ID}`,
  reportersList: '/users/search?maxResults=1000',
  labels: '/label',
  getCustomFieldId: (customFieldId) => `/field/${customFieldId}/context`,
  getCustomFieldOptions: (customField, id) => `/field/${customField}/context/${id}/option`,
  ownProfile: '/authentication/principal',
};
