import {
  GET_TABLE_COLUMNS,
  TOGGLE_TABLE_COLUMNS,
} from '@constants/iTrackerConstants';

const INIT_STATE = {
  columns: [],
  loading: false,
};

/**
 * @description Basic reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case GET_TABLE_COLUMNS:
      return { ...state, columns: action.payload };

    case TOGGLE_TABLE_COLUMNS:
      newState = {
        ...state,
        columns: state.columns.map(
          (column) => (column.id === action.payload.id
            ? { ...column, visible: !column.visible }
            : column
          ),
        ),
      };

      return { ...state, ...newState };

    default:
      return state;
  }
};
