// Customizer const
export const TOP_FILTER_PREFIX = 'topf_';
export const TABLE_FILTER_PREFIX = 'tf_';
export const MAP_FILTER_PREFIX = 'mf_';

export const ACCESS_TOKEN_PARAM = 'access_token';
export const REFRESH_TOKEN_PARAM = 'refresh_token';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const CURRENT_USER_KEY = 'ownUserProfile';
export const IMPERSONATE_TOKEN = 'impersonate_token';
export const INVITE_TOKEN = 'invite_token';
export const CALL_ROOM_TOKEN = 'call_token';

export const TOP_FILTER_PAGE_SIZE = 100000;
export const LISTING_TABLES_PAGE_SIZE = 20;

export const HTTP_ERROR_MESSAGES = {
  400: 'bad_request',
  403: 'forbidden',
  404: 'not_found',
  409: 'already_exists',
  500: 'server_error',
  502: 'bad_gateway',
  503: 'service_not_available',
  504: 'gateway_timeout',
};

export const USER_ROLES = [
  { caregility_admin: 'CAREGILITY_ADMIN' },
  { super_admin: 'SUPER_ADMIN' },
  { portal_customer_admin: 'CUSTOMER_ADMIN' },
  { portal_facility_admin: 'FACILITY_ADMIN' },
  { iconsult_desktop: 'I_CONSULT_DESKTOP' },
  { iconsult_mobile: 'I_CONSULT_MOBILE}' },
  { iobserver: 'I_OBSERVER' },
  { notification_portal: 'NOTIFICATION_PORTAL' },
  { portal_org_admin: 'ORGANIZATION_ADMIN' },
  { reporting: 'REPORTING' },
  { technician: 'TECHNICIAN' },
  { portal_unit_admin: 'UNIT_ADMIN' },
  { user_management_admin: 'USER_MANAGEMENT' },
];
