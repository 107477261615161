import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from '@reducers/Settings';
import Notes from '@reducers/Notes';
import Contact from '@reducers/Contact';
import Common from '@reducers/Common';
import ColumnsReducer from '@reducers/iTracker/ColumnsReducer';
import FilterReducer from '@reducers/iTracker/FilterReducer';
import UserReducer from '@reducers/iTracker/UserReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  notes: Notes,
  contact: Contact,
  common: Common,
  columnsReducer: ColumnsReducer,
  filterReducer: FilterReducer,
  userReducer: UserReducer,
});

export default createRootReducer;
