import {
  TOGGLE_TABLE_COLUMNS,
  GET_TABLE_COLUMNS,
} from '@constants/iTrackerConstants';

/**
 * @description Toggles column
 * @param {String} payload
 * @returns {Object}
 */
export const toggleTableColumn = (payload) => ({
  type: TOGGLE_TABLE_COLUMNS,
  payload,
});

/**
 * @description Get table columns
 * @param {Array} payload
 * @returns {Array}
 */
export const getTableColumns = (payload) => ({
  type: GET_TABLE_COLUMNS,
  payload,
});
