import {
  GET_VERSIONS,
  GET_VERSIONS_SUCCESS,
  GET_STATUSES,
  GET_STATUSES_SUCCESS,
  GET_ISSUES,
  GET_ISSUES_SUCCESS,
  FILTER_ISSUES,
  FILTER_ISSUES_SUCCESS,
  GET_CUSTOM_FIELD_OPTIONS,
  GET_CUSTOM_FIELD_OPTIONS_SUCCESS,
  GET_PRIORITIES,
  GET_PRIORITIES_SUCCESS,
  GET_ASSIGNEES,
  GET_ASSIGNEES_SUCCESS,
  GET_REPORTERS,
  GET_REPORTERS_SUCCESS,
  GET_LABELS,
  GET_LABELS_SUCCESS,
  ADD_FILTER,
  SET_PAGE_NUMBER,
} from '@constants/iTrackerConstants';

/**
 * @description Fetch versions request
 * @returns {Object}
 */
export const fetchVersions = () => ({
  type: GET_VERSIONS,
});

/**
 * @description Fetch versions success
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchVersionsSuccess = (payload) => ({
  type: GET_VERSIONS_SUCCESS,
  payload,
});

/**
 * @description Fetch statuses request
 * @returns {Object}
 */
export const fetchStatuses = () => ({
  type: GET_STATUSES,
});

/**
 * @description Fetch statuses success
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchStatusesSuccess = (payload) => ({
  type: GET_STATUSES_SUCCESS,
  payload,
});

/**
 * @description Fetch issues request
 * @returns {Object}
 */
export const fetchIssues = (startAt, maxResults) => ({
  type: GET_ISSUES,
  startAt,
  maxResults,
});

/**
 * @description Fetch issues success
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchIssuesSuccess = (payload) => ({
  type: GET_ISSUES_SUCCESS,
  payload,
});

/**
 * @description Filter issues request
 * @param {String} status
 * @param {String} reporter
 * @returns {Object}
 */
export const filterIssues = (filters, startAt, maxResults) => ({
  type: FILTER_ISSUES,
  filters,
  startAt,
  maxResults,
});

/**
 * @description Filter issues success
 * @param {Object} payload
 * @returns {Object}
 */
export const filterIssuesSuccess = (payload) => ({
  type: FILTER_ISSUES_SUCCESS,
  payload,
});

/**
 * @description Fetch custom field request
 * @param {String} field
 * @returns {Object}
 */
export const fetchCustomFieldOptions = (field) => ({
  type: GET_CUSTOM_FIELD_OPTIONS,
  field,
});

/**
 * @description Fetch versions success
 * @param {Object} payload
 * @param {String} field
 * @returns {Object}
 */
export const fetchCustomFieldOptionsSuccess = (payload, field) => ({
  type: GET_CUSTOM_FIELD_OPTIONS_SUCCESS,
  payload,
  field,
});

/**
 * @description Fetch priorities request
 * @returns {Object}
 */
export const fetchPriorities = () => ({
  type: GET_PRIORITIES,
});

/**
 * @description Fetch priorities success
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchPrioritiesSuccess = (payload) => ({
  type: GET_PRIORITIES_SUCCESS,
  payload,
});

/**
 * @description Fetch assignees
 * @returns {Object}
 */
export const fetchAssignees = () => ({
  type: GET_ASSIGNEES,
});

/**
 * @description Fetch assignees success
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchAssigneesSuccess = (payload) => ({
  type: GET_ASSIGNEES_SUCCESS,
  payload,
});

/**
 * @description Fetch reporters
 * @returns {Object}
 */
export const fetchReporters = () => ({
  type: GET_REPORTERS,
});

/**
 * @description Fetch reporters success
 * @param {Object} payload
 * @returns {Array}
 */
export const fetchReportersSuccess = (payload) => ({
  type: GET_REPORTERS_SUCCESS,
  payload,
});

export const addFilter = (filters, item) => ({
  type: ADD_FILTER,
  filters,
  item,
});

export const setPage = (page, size) => ({
  type: SET_PAGE_NUMBER,
  page,
  size,
});

export const fetchLabels = () => ({
  type: GET_LABELS,
});

export const fetchLabelsSuccess = (payload) => ({
  type: GET_LABELS_SUCCESS,
  payload,
});
